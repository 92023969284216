import { Document, Image, Link, Page, Text, View } from '@react-pdf/renderer'
import {
	QuoteData,
	IQuoteLine,
	IQuoteAdditionalLines,
	IQuoteFiles,
} from 'models'
import { tableStyles, styles } from './Styles'
import { Heading, ClientInfo } from './Heading'
import { Heading as NewHeading } from './NewHeading'
import { Description } from './Description'
import { ColumnTypes, Table } from './Table'
import React from 'react'
import { numberFormat } from 'utilities'
import { u } from '@fullcalendar/resource/internal-common'

interface QuotePDFDocumentProps {
	quote_data: QuoteData
	quote_lines: IQuoteLine[]
	quote_addons: IQuoteAdditionalLines[]
	quote_files: IQuoteFiles[]
}

export const TableRow = ({ item }: { item?: any }) => {
	return (
		<View
			style={[
				tableStyles.tableRow,
				{ backgroundColor: item?.backgroundColor || '' },
			]}>
			<View style={tableStyles.tableColStart}>
				<Text style={[tableStyles.tableCell, { paddingLeft: '8' }]}>
					{item?.service}
				</Text>
			</View>
			<View style={[tableStyles.tableColEnd]}>
				<Text style={tableStyles.tableCell}>{item?.price}</Text>
			</View>
		</View>
	)
}

export const TableRowEnd = ({ item }: { item?: any }) => (
	<View style={[tableStyles.tableRow, { borderBottom: 1 }]} fixed>
		<View style={tableStyles.tableColStart}>
			<Text style={tableStyles.tableCell}>{item?.service}</Text>
		</View>
		<View style={tableStyles.tableColEnd}>
			<Text style={tableStyles.tableCell}>{item?.price}</Text>
		</View>
	</View>
)

export const SummaryQuotePDFDocument = ({
	quote_data,
	quote_lines,
	quote_addons,
	quote_files,
}: QuotePDFDocumentProps) => {
	const filtered_quote_files = quote_files.filter(
		(file) => file.show_in_quote === true && file.url.includes('pdf') === false
	)

	const generatePages = (filtered_quote_files: IQuoteFiles[]) => {
		const pages = []
		for (let i = 0; i < filtered_quote_files.length; i++) {
			const file = filtered_quote_files[i]
			console.log('file', file?.url)
			pages.push(
				<Page key={i + 1} style={{ paddingTop: 40, paddingBottom: 40 }}>
					<View
						key={i + 1}
						style={{
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: 'white',
						}}>
						<View
							style={{
								margin: 10,
								backgroundColor: 'lightgrey',
							}}>
							<Image style={{}} src={file.url} />
						</View>
					</View>
					<Text
						style={{
							position: 'absolute',
							bottom: 20,
							fontSize: 10,
							left: 0,
							right: 0,
							textAlign: 'center',
							color: 'lightgray',
						}}
						render={({ pageNumber, totalPages }) =>
							`${pageNumber} / ${totalPages}`
						}
						fixed
					/>
				</Page>
			)
		}
		return pages
	}

	const stages = quote_lines.map((line) => line.zone_label)

	const uniqueStages = Array.from(new Set([...stages]))

	const quote_stages = uniqueStages.map((stage) => {
		const stage_lines = quote_lines.filter((line) => line.zone_label === stage)
		const stage_total = stage_lines.reduce((acc, line) => {
			const line_total = line.total - line.weekly_hire_fee * line.hire_period
			return Number(acc) + Number(line_total)
		}, 0)
		const total_hire_fee = stage_lines.reduce((acc, line) => {
			const line_total = line.weekly_hire_fee * line.hire_period
			return Number(acc) + Number(line_total)
		}, 0)
		const total_hire_fee_after = stage_lines.reduce((acc, line) => {
			const line_total = line.weekly_hire_after_period
			return Number(acc) + Number(line_total)
		}, 0)
		const hire_period = stage_lines[0].hire_period

		return {
			stage,
			stage_lines,
			stage_total,
			hire_period,
			total_hire_fee,
			total_hire_fee_after,
		}
	})

	console.log(quote_data, 'QUOTE_DATA')

	const branch = quote_data.branch
	const client = quote_data.clientData
	const cashAccountCode = branch === 'Adelaide' ? '500410' : '600650'
	const customerCode = client.finance_type === 'Credit' ? client.customer_code : cashAccountCode

	quote_lines.map((line) => {
		return { zone: line.zone_label, invoice: line }
	})

	const quote_lines_subtotal = quote_lines.reduce((acc, line) => {
		const line_total = line.total
		return Number(acc) + Number(line_total)
	}, 0)

	const addon_lines_subtotal = quote_addons.reduce((acc, line) => {
		return Number(acc) + Number(line.total_cost)
	}, 0)

	const subtotal = quote_lines_subtotal + addon_lines_subtotal
	const subtotal_gst = subtotal * 0.1
	const total = subtotal + subtotal_gst

	const additionals_total = addon_lines_subtotal

	console.log(stages)
	return (
		<Document title={`Oldfields Quote #${quote_data.quote_num}`}>
			<Page size="A4" style={styles.page}>
				<NewHeading />
				<View style={{ flexDirection: 'row', display: 'flex' }} fixed>
					<View
						style={{
							fontFamily: 'Rosario',
							flexDirection: 'column',
							display: 'flex',
							width: '50%',
							paddingRight: 8,
						}}>
						<Text style={{ fontSize: 16 }}>
							Adelaide Scaffold Solutions Pty Ltd
						</Text>
						<Text style={{ fontSize: 12, marginBottom: 8 }}>
							ABN 61 111 683 697
						</Text>
						{quote_data?.branch == 'Adelaide' ? (
							<>
								<Text style={{ fontSize: 10 }}>5-7 Peekarra St</Text>
								<Text style={{ fontSize: 10 }}>Regency Park</Text>
								<Text style={{ fontSize: 10 }}>South Australia, 5010</Text>
							</>
						) : null}
						{quote_data?.branch == 'Perth' ? (
							<>
								<Text style={{ fontSize: 10 }}>7 Morse Road</Text>
								<Text style={{ fontSize: 10 }}>Bibra Lake</Text>
								<Text style={{ fontSize: 10 }}>Western Australia 6162</Text>
							</>
						) : null}
						<Text style={{ fontSize: 10, textAlign: 'right' }}>
							Phone: 13 62 60
						</Text>
						<Text style={{ fontSize: 10, textAlign: 'right' }}>
							E-mail:{' '}
							<Link src={`mailto:${quote_data?.estimatorData?.email}`}>
								{quote_data?.estimatorData?.email}
							</Link>
						</Text>
					</View>
					<View
						style={{
							fontFamily: 'Rosario',
							flexDirection: 'column',
							display: 'flex',
							width: '50%',
							alignContent: 'flex-end',
							justifyContent: 'flex-end',
							paddingLeft: 8,
						}}>
						<Text style={{ fontSize: 10, textAlign: 'right' }}>
							Quote #: {quote_data.quote_num}
						</Text>
						<Text style={{ fontSize: 10, textAlign: 'right' }}>
							Date: {new Date(quote_data.createdAt).toLocaleDateString('en-AU')}
						</Text>
						<Text style={{ fontSize: 10, textAlign: 'right' }}>
							Purchase Order No: {(quote_data.PO_Number && quote_data.PO_Number?.length > 0) ? quote_data.PO_Number : 'N/A'}
						</Text>
					</View>
				</View>
				<View
					fixed
					style={{ flexDirection: 'row', display: 'flex', marginBottom: 16 }}>
					<View
						style={{
							fontFamily: 'Rosario',
							flexDirection: 'column',
							display: 'flex',
							width: '50%',
							marginTop: 16,
							paddingRight: 8,
						}}>
						<Text style={{ fontSize: 12, fontWeight: 'heavy' }}>Customer:</Text>
						<Text style={{ fontSize: 9, marginTop: 4 }}>
							Name: {quote_data.clientData.client_name}
						</Text>
						<Text style={{ fontSize: 9, marginTop: 4 }}>
							Phone: {quote_data.clientData.phone}
						</Text>
						<Text style={{ fontSize: 9, marginTop: 4 }}>
							Email: {quote_data.clientData.email}
						</Text>
						{/* <Text style={{ fontSize: 9, marginTop: 4 }}>
							Account #: {customerCode}
						</Text> */}
					</View>
					<View
						style={{
							fontFamily: 'Rosario',
							flexDirection: 'column',
							display: 'flex',
							width: '50%',
							alignContent: 'flex-start',
							justifyContent: 'flex-start',
							marginTop: 16,
							paddingLeft: 8,
						}}>
						<Text style={{ fontSize: 12, fontWeight: 'heavy' }}>
							Site Address:
						</Text>
						<Text style={{ fontSize: 10, textAlign: 'left' }}>
							{quote_data.street}
						</Text>
					</View>
				</View>
				<Description description={quote_data.scope_of_work} />
				{quote_data?.terms && quote_data?.terms.trim() !== '' && (
					<View>
						<Text
							style={{
								fontFamily: 'Rosario',
								flexDirection: 'column',
								fontWeight: 'heavy',
								display: 'flex',
								width: '50%',
								marginTop: 16,
								paddingRight: 8,
							}}>
							Additional Conditions
						</Text>
						<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
							{quote_data.terms}
						</Text>
					</View>
				)}
				<View style={tableStyles.section} break>
					<View style={tableStyles.tableHeader} fixed>
						<View style={[tableStyles.tableHeaderColStart]}>
							<Text style={tableStyles.tableCell}>Service</Text>
						</View>
						<View style={tableStyles.tableHeaderColEnd}>
							<Text style={tableStyles.tableCell}>Price</Text>
						</View>
					</View>
					{quote_stages.map((stage, index) => {
						return (
							<View key={index} wrap={false}>
								<View style={tableStyles.tableRow}>
									<View style={tableStyles.tableColStart}>
										<Text style={tableStyles.tableCell}>
											<Text style={{ fontWeight: 'bold' }}>
												Stage - {stage.stage}
											</Text>
										</Text>
									</View>
									<View style={tableStyles.tableColEnd}>
										<Text style={tableStyles.tableCell}></Text>
									</View>
								</View>
								<TableRow
									item={{
										service: `Total Labour and Services incl. ${stage.hire_period} weeks hire`,
										price: numberFormat.format(
											Number(stage.total_hire_fee + Number(stage.stage_total))
										),
										backgroundColor: 'whitesmoke',
									}}
								/>
								{!stage.total_hire_fee_after && stage.total_hire_fee > 0 && stage.hire_period > 0 && (
									<TableRow
										item={{
											service: `Weekly Hire Fee after initial period ${numberFormat.format(
												stage.total_hire_fee / stage.hire_period
											)} p/w`,
											backgroundColor: '#E8E8E8',
										}}
									/>
								)}
								{stage.total_hire_fee_after && (
									<TableRow
										item={{
											service: `Weekly Hire after the period ${numberFormat.format(
												stage.total_hire_fee_after
											)}`,
											backgroundColor: '#E8E8E8',
										}}
									/>
								)}
							</View>
						)
					})}
					{quote_addons?.length > 0 && (
						<>
							<View style={tableStyles.tableRow}>
								<View style={tableStyles.tableColStart}>
									<Text style={tableStyles.tableCell}>
										<Text style={{ fontWeight: 'bold' }}>
											Additional Services
										</Text>
									</Text>
								</View>
								<View style={tableStyles.tableColEnd}>
									<Text style={tableStyles.tableCell}></Text>
								</View>
							</View>
							<TableRow
								item={{
									service: 'Total Services',
									price: numberFormat.format(additionals_total),
								}}
							/>
						</>
					)}
					<TableRow />
					<TableRow />
					<TableRow />
					<TableRowEnd />
					<View style={[tableStyles.tableRow]}>
						<View style={tableStyles.tableSummaryStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'semibold', textAlign: 'right' }}>
									SUB TOTAL
								</Text>
							</Text>
						</View>
						<View style={tableStyles.tableSummaryEnd}>
							<Text style={[tableStyles.tableCell, { textAlign: 'right' }]}>
								{numberFormat.format(subtotal)}
							</Text>
						</View>
					</View>
					<View style={[tableStyles.tableRow]}>
						<View style={tableStyles.tableSummaryStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'semibold', textAlign: 'right' }}>
									GST
								</Text>
							</Text>
						</View>
						<View style={tableStyles.tableSummaryEnd}>
							<Text style={[tableStyles.tableCell, { textAlign: 'right' }]}>
								{numberFormat.format(subtotal_gst)}
							</Text>
						</View>
					</View>
					<View style={[tableStyles.tableRow]}>
						<View style={tableStyles.tableSummaryStart}>
							<Text style={tableStyles.tableCell}>
								<Text style={{ fontWeight: 'semibold', textAlign: 'right' }}>
									TOTAL (inc. GST)
								</Text>
							</Text>
						</View>
						<View style={tableStyles.tableSummaryEnd}>
							<Text style={[tableStyles.tableCell, { textAlign: 'right' }]}>
								{numberFormat.format(total)}
							</Text>
						</View>
					</View>
					<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						By acceptance of this quote you hereby agree to our terms and
						conditions found below {'\n'}
						<Link
							style={{ color: 'green' }}
							src={`https://oldfields.scaffm8.co.nz/accept-quote/${quote_data.quote_token}`}>
							Accept Quote
						</Link>
					</Text>
					<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						<Link src="https://oldfields.com.au/wp-content/uploads/2024/04/OLH-HIRE-TCs-24.pdf">
							View our Terms and Conditions here
						</Link>
					</Text>
					<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						All non-account clients are required to pay in full prior to work
						commencing, if you are a business and require a trading account
						click below link. {'\n'} Please select “Adelaide Scaffold Solutions”
						{'\n'}
						<Link
							style={{ color: 'green' }}
							src={`https://oldfields.applyeasy.com.au/ausapplication/introduction`}>
							Apply for Trading Account
						</Link>
					</Text>
				</View>
				<Text
					style={{
						position: 'absolute',
						bottom: 20,
						fontSize: 10,
						left: 0,
						right: 0,
						textAlign: 'center',
						color: 'lightgray',
					}}
					render={({ pageNumber, totalPages }) =>
						`${pageNumber} / ${totalPages}`
					}
					fixed
				/>
			</Page>
			{generatePages(filtered_quote_files)}
		</Document>
	)
}
