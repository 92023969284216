import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'

export const useVehicles = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()
	const userState = useSelector((store: AppStore) => store.user)


	const fetchAllVehicles = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverVehicleRoutes.getAllVehicles
			)

			const newValues = response.data.map(
				(vehicle: {
					RegoDue: string
					FuelCardDate: string
					ServiceDueDate: string
				}) => {
					return {
						...vehicle,
						RegoDue_date: moment(vehicle.RegoDue, 'DD/MM/YYYY').toDate(),
						FuelCardDate_date: moment(
							vehicle.FuelCardDate,
							'DD/MM/YYYY'
						).toDate(),
						ServiceDueDate_date: moment(
							vehicle.ServiceDueDate,
							'DD/MM/YYYY'
						).toDate(),
					}
				}
			)

			return newValues ? newValues?.filter((vehicle: any) => vehicle.branch === userState.branch || userState.branch === 'Head Office') : null
		} catch (error) {
			showError('Something went wrong getting Vehicles')
			throw new Error('Something went wrong getting Vehicles')
		}
	}

	const { data, isLoading, error } = useQuery(['vehicles'], fetchAllVehicles)

	return { data, isLoading, error }
}
