import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { JobSchedulerUtils } from 'common/Scheduler'
import { Spinner } from 'common'
import { StyledWrapper } from '../../common/Scheduler/styledComponents'
import { format } from 'date-fns'

export function JobScheduler() {
	const {
		visitsData,
		visitsLoading,
		headerToolbar,
		eventContent,
		eventDrop,
		formatEvents,
	} = JobSchedulerUtils()

	if (visitsLoading) {
		return <Spinner />
	}
	return (
		<StyledWrapper>
			<FullCalendar
				headerToolbar={{
					...headerToolbar,
					center: 'Day,Week,Month',
				}}
				views={{
					Month: {
						type: 'dayGridMonth',
						slotLabelInterval: { days: 1 },
						weekends: true,
						eventContent,
					},
					Week: {
						type: 'dayGridWeek',
						slotLabelInterval: { days: 1 },
						weekends: true,
						eventContent,
						dayHeaderContent: (args) => {
							const formattedDate = format(args.date, 'dd/MM')
							const dayOfWeek = format(args.date, 'EEE')
							return (
								<span>
									{dayOfWeek} - {formattedDate}
								</span>
							)
						},
					},
					Day: {
						type: 'dayGridDay',
						slotLabelInterval: { days: 1 },
						weekends: true,
						eventContent,
					},
				}}
				eventContent={eventContent}
				plugins={[
					dayGridPlugin,
					timeGridPlugin,
					interactionPlugin,
					resourceTimelinePlugin,
				]}
				buttonText={{
					today: 'Today',
					month: 'Month',
					week: 'Week',
					day: 'Day',
					list: 'List',
				}}
				initialView="Month"
				height="auto"
				editable
				selectable
				dayMaxEvents
				firstDay={1}
				events={formatEvents()}
				eventDrop={eventDrop}
				schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
			/>
		</StyledWrapper>
	)
}
