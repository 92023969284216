import { useState } from 'react'
import {
	Checkbox,
	DateSelect,
	Dropdown,
	Input,
	SideModal,
	Spinner,
	TextArea,
} from 'common'
import { useFormik } from 'formik'
import { active_inactive_options } from 'models'
import { job_status_options, job_status_options_Edit } from 'models/jobs.model'
import moment from 'moment'
import { useEffect } from 'react'
import {
	ClientServices,
	ContactServices,
	JobsServices,
	VisitServices,
} from 'services'
import {
	OptionsForDropdown,
	isMobileOnly,
	isVoidOnly,
	isVoidOrMobileJob,
} from 'utilities'
import * as Yup from 'yup'
import { VariationTaskForm } from './VariationForm'
import Autocomplete from 'react-google-autocomplete'
import { AppConfig } from 'config'
import {
	ExclamationCircleIcon,
	PlusCircleIcon,
} from '@heroicons/react/24/solid'
import { CreateClientsForm } from 'components/Clients'
import { MobileScaffoldingForm } from './MobileScaffoldingForm'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { branchOptions } from 'models/branch'

const jobTypeOptions = [
	{
		value: 'Residential Construction',
		label: 'Residential Construction',
	},
	{
		value: 'Civil Construction',
		label: 'Civil Construction',
	},
	{
		value: 'Commercial Building',
		label: 'Commercial Building',
	},
	{
		value: 'Void Protection',
		label: 'Void Protection',
	},
	{
		value: 'Mobile Scaffold',
		label: 'Mobile Scaffold',
	},
	{
		value: 'Tier 1',
		label: 'Tier 1',
	},
	{
		value: 'Facilities Management',
		label: 'Facilities Management',
	},
	{
		value: 'Maintenance',
		label: 'Maintenance',
	},
	{
		value: 'Mining',
		label: 'Mining',
	},
]

const brandingOptions = [
	{
		value: 'Oldfields Scaffolding',
		label: 'Oldfields Scaffolding',
	},
]

const voidVisitOptions = [
	{ value: 'create_install_visit', label: 'Install Visit?' },
	{
		value: 'create_dismantle_visit',
		label: 'Dismantle Visit?',
	},
]
interface IProps {
	job_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IInitialValues {
	client_id: string
	job_type: string
	branding: string
	site: string
	start_date: string
	end_date: string
	job_status: string
	notes: string
	status: string
	PO_Number: string
	Requester: string
	type: string
	description: string
	total_hours: number
	percentage_erect: number
	percentage_dismantle: number
	percentage_complete: number
	LastEditDate: string
	hire_rate: number | null
	task_value: number | null
	hire_period: number | null
	visit_options: string[]
	tower_size: string
	deck_level: string
	invoice_paid: string
	branch: string | null
}

export const JobForm = ({
	job_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const userState = useSelector((store: AppStore) => store.user)
	const googleApiKey = AppConfig.GoogleMapsApiKey
	const [clientForm, setClientForm] = useState(false)
	const [validationSchema, setValidationSchema] = useState({})
	const { data: clientsData, isLoading: clientsLoading } =
		ClientServices.useClients(true)
	const { data: contactsData } = ContactServices.useContacts()
	const { createVariationTask } = JobsServices.useCreateVariationTask()
	const { createVisit } = VisitServices.useCreateVisit()

	const { createJob } = JobsServices.useCreateJob()
	const { update } = JobsServices.useUpdateJob()
	const { data: jobData } = JobsServices.useJobById(job_id || undefined)

	const initialValues: IInitialValues = {
		client_id: jobData?.client_id || '',
		job_type: jobData?.job_type || '',
		branding: 'Oldfields Scaffolding',
		site: jobData?.site || '',
		start_date: jobData?.start_date
			? moment(jobData.start_date).format('DD/MM/YYYY').toString()
			: moment().format('DD/MM/YYYY').toString(),
		end_date: jobData?.end_date
			? moment(jobData.end_date).format('DD/MM/YYYY').toString()
			: '',
		job_status: jobData?.job_status || 'Pending Handover',
		notes: jobData?.notes || '',
		status: jobData?.status || 'Active',
		description: '',
		percentage_erect: 0,
		percentage_dismantle: 0,
		percentage_complete: 0,
		total_hours: 0,
		LastEditDate: '',
		PO_Number: '',
		Requester: '',
		type: 'Install',
		hire_rate: 0,
		task_value: 0,
		hire_period: 16,
		visit_options: ['create_install_visit'],
		tower_size: '',
		deck_level: '',
		invoice_paid: jobData?.invoice_paid || 'N',
		branch: jobData?.branch || null,
	}

	const formik = useFormik<IInitialValues>({
		initialValues,
		validationSchema: Yup.object(validationSchema),
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create') {
				const JobData = {
					client_id: values.client_id,
					job_type: values.job_type,
					branding: values.branding,
					site: values.site,
					start_date: values.start_date,
					end_date: values.end_date,
					job_status: values.job_status,
					tower_size: values.tower_size,
					deck_level: values.deck_level,
					branch: values.branch,
					invoice_paid: values.invoice_paid,
					notes: values.notes,
				}
				const jobResponse = await createJob(JobData)
				const {
					PO_Number,
					Requester,
					description,
					percentage_erect,
					percentage_dismantle,
					total_hours,
					task_value,
					hire_rate,
					type,
				} = values

				const taskType = isVoidOnly(formik.values.job_type)
					? 'Void Protection'
					: isMobileOnly(formik.values.job_type)
					? 'Mobile Scaffold'
					: type

				const data = {
					PO_Number,
					Requester,
					description,
					percentage_erect,
					percentage_dismantle,
					total_hours,
					LastEditDate: moment(values.LastEditDate, 'DD/MM/YYYY').toDate(),
					task_value,
					hire_rate,
					type: taskType,
					zone_label: isVoidOnly(formik.values.job_type)
						? 'Void Protection'
						: 'Scaffolding',
					zone: 1,
				}

				if (
					jobResponse?.data?.job_id &&
					isVoidOrMobileJob(formik.values.job_type)
				) {
					const newTask = await createVariationTask(
						Number(jobResponse.data.job_id),
						data
					)
					// console.log(newTask)
					if (isVoidOnly(formik.values.job_type)) {
						if (values.visit_options.includes('create_install_visit')) {
							const installVisit = {
								job_id: jobResponse?.data?.job_id,
								date: values.start_date,
								visit_status: 'In Progress',
								status: 'Active',
								team_leader_id: 59,
								type: 'Install',
								percentage_complete: 0,
								staff_ids: [],
								staff_labels: [],
								task_ids: [Number(newTask.data?.task?.id)],
							}
							await createVisit(installVisit)
						}
						if (values.visit_options.includes('create_dismantle_visit')) {
							const dismantleVisit = {
								job_id: jobResponse?.data?.job_id,
								date: values.end_date,
								visit_status: 'In Progress',
								status: 'Active',
								team_leader_id: 59,
								type: 'Dismantle',
								percentage_complete: 0,
								staff_ids: [],
								staff_labels: [],
								task_ids: [Number(newTask.data?.task?.id)],
							}
							await createVisit(dismantleVisit)
						}
					}
				}
			}
			if (formType === 'update' && job_id) {
				const JobData = {
					site: values.site,
					start_date: values.start_date,
					end_date: values.end_date,
					status: values.status,
					job_status: values.job_status,
					invoice_paid: values.invoice_paid,
					notes: values.notes,
					branch: values.branch,
				}
				await update(job_id, JobData)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	useEffect(() => {
		formik.resetForm()
	}, [open])

	useEffect(() => {
		if (isVoidOrMobileJob(formik.values.job_type) && formType === 'create') {
			setValidationSchema({
				client_id: Yup.string().required('The client is required'),
				job_type: Yup.string().required('The job type is required'),
				Requester: Yup.string().required('Requester is required'),
				description: Yup.string().required('Description is required'),
				site: Yup.string().required('Site Address is required'),
				percentage_erect: Yup.number().typeError(
					'Percentage Erect must be number'
				),
				percentage_dismantle: Yup.number().typeError(
					'Percentage Dismantle must be number'
				),
				hire_rate: Yup.number()
					.typeError('Hire Rate must be number')
					.required('Weekly Hire Rate is required'),
				task_value: Yup.number()
					.typeError('Task Value must be number')
					.required('Task Value is required'),
			})

			formik.setFieldValue('job_status', 'In Progress')
			formik.setFieldValue('hire_rate', 30)
			formik.setFieldValue('task_value', 530)
		} else {
			if (formType === 'create') {
				formik.setFieldValue('job_status', 'Pending Handover')
				setValidationSchema({
					site: Yup.string().required('Site Address is required'),
					client_id: Yup.string().required('The client is required'),
					job_type: Yup.string().required('The job type is required'),
				})
			} else {
				setValidationSchema({
					site: Yup.string().required('Site Address is required'),
					start_date: Yup.string().required('The start date is required'),
					end_date: Yup.string().required('The end date is required'),
				})
			}
		}
	}, [formik.values.job_type])

	useEffect(() => {
		if (formik?.values?.client_id) {
			const client = clientsData?.filter(
				(client: { id: string }) => client.id === formik.values.client_id
			)[0]
			console.log(client, 'client')
			if (client?.default_weeks && isVoidOnly(formik.values.job_type)) {
				formik.setFieldValue('hire_period', client?.default_weeks)
				formik.setFieldValue(
					'end_date',
					moment()
						.add(client?.default_weeks, 'weeks')
						.format('DD/MM/YYYY')
						.toString()
				)
			}
		}
	}, [formik.values.client_id, formik.values.job_type])

	useEffect(() => {
		if (formType === 'update' && job_id) {
			return
		}
		formik.setFieldValue(
			'end_date',
			moment()
				.add(formik.values.hire_period, 'weeks')
				.format('DD/MM/YYYY')
				.toString()
		)
	}, [formik.values.hire_period])

	useEffect(() => {
		if (job_id !== null && job_id !== undefined) {
			return
		}
		const startDate = moment(formik.values.start_date, 'DD/MM/YYYY')
		const hirePeriod = formik.values.hire_period
		const endDate = startDate.add(hirePeriod, 'weeks').format('DD/MM/YYYY')

		if (isVoidOnly(formik.values.job_type) === true) {
			formik.setFieldValue('end_date', endDate)
		}
	}, [formik.values.start_date, formik.values.hire_period])

	if (job_id && clientsLoading) {
		return (
			<div className="absolute top-1/3 right-1/4 transform translate-x-2/3 -translate-y-1/2 bg-white border border-gray-300 rounded-lg p-4 shadow-lg z-50">
				<Spinner />
			</div>
		)
	}

	// console.log('val', formik.errors)

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between">
					{userState?.branch === 'Head Office' && (
						<div className="flex items-center px-2">
							<Dropdown
								label="Branch"
								id="branch"
								options={branchOptions}
								value={String(formik.values.branch)}
								onChange={formik.setFieldValue}
								onBlur={formik.setFieldTouched}
								error={formik.errors.branch}
							/>
						</div>
					)}
				</div>
				{formType === 'create' && (
					<>
						<div className="flex items-center px-3">
							<div className="flex flex-col w-full">
								<Dropdown
									label="Client"
									id="client_id"
									options={OptionsForDropdown(clientsData, 'id', 'client_name')}
									value={formik.values.client_id}
									onChange={formik.setFieldValue}
									onBlur={formik.setFieldTouched}
									error={formik.errors.client_id}
								/>
								<div className="flex items-center pl-2">
									<PlusCircleIcon className="w-6 h-6 text-indigo-500" />
									<button
										type="button"
										className="pl-1 font-semibold leading-5 text-sm text-gray-600 hover:text-gray-800"
										onClick={() => setClientForm(true)}>
										Add New Client
									</button>
								</div>
							</div>
							<div className="w-full pb-6">
								<Dropdown
									label="Job Type"
									id="job_type"
									options={jobTypeOptions}
									value={formik.values.job_type}
									onChange={formik.setFieldValue}
									onBlur={formik.setFieldTouched}
									error={formik.errors.job_type}
								/>
							</div>
						</div>
						<div className="flex items-center px-3">
							<Dropdown
								label="Branding"
								id="branding"
								options={brandingOptions}
								value={formik.values.branding}
								onChange={formik.setFieldValue}
								onBlur={formik.setFieldTouched}
								disabled={isVoidOrMobileJob(formik.values.job_type)}
							/>
						</div>
					</>
				)}
				<>
					<div className="flex items-center px-3">
						<div className="w-full px-2 py-2 h-full">
							<label className="block mb-1 text-sm font-medium text-gray-700">
								Address Search
							</label>
							<Autocomplete
								apiKey={googleApiKey}
								placeholder="Enter an address to search"
								style={{ width: '100%' }}
								className="autocomplete border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-[40px] rounded-md shadow-sm pl-3 "
								onPlaceSelected={(place) => {
									formik.setFieldValue('site', place.formatted_address)
								}}
								options={{
									types: ['address'],
									componentRestrictions: { country: 'au' },
								}}
							/>
						</div>
					</div>
					<div className="flex items-center px-3">
						<div className="w-full px-2 py-2 h-full">
							<label className="block mb-1 text-sm font-medium text-gray-700">
								Site Address
							</label>
							<input
								type="text"
								id="site"
								placeholder="Site Address"
								className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
								onChange={(e) => {
									formik.setFieldValue('site', e.target.value)
								}}
								onBlur={formik.handleBlur}
								value={formik.values.site}
							/>
							<p
								className="mt-1 text-sm text-red-600 flex items-center"
								id="multiselect-error">
								{formik.errors.site && (
									<>
										<ExclamationCircleIcon
											className="w-5 h-5 text-red-500"
											aria-hidden="true"
										/>
										<span className="ml-2">{formik.errors.site}</span>
									</>
								)}
							</p>
						</div>
					</div>
				</>

				{isVoidOrMobileJob(formik.values.job_type) == false ? (
					<>
						<div className="flex items-center px-3">
							<DateSelect
								title="Start Date"
								id="start_date"
								value={formik.values.start_date}
								onChange={formik.setFieldValue}
							/>
							<DateSelect
								title="Finish Date"
								id="end_date"
								value={formik.values.end_date}
								onChange={formik.setFieldValue}
							/>
						</div>
					</>
				) : (
					<div className="flex items-center px-3">
						<DateSelect
							title="Start Date"
							id="start_date"
							value={formik.values.start_date}
							onChange={formik.setFieldValue}
						/>
						<DateSelect
							title="Finish Date"
							id="end_date"
							value={formik.values.end_date}
							onChange={formik.setFieldValue}
							disabled={isVoidOnly(formik.values.job_type) === true}
						/>
					</div>
				)}
				{formType === 'create' && isVoidOrMobileJob(formik.values.job_type) ? (
					<div>
						{isMobileOnly(formik.values.job_type) && (
							<MobileScaffoldingForm formik={formik} formType="create" />
						)}
						{formType === 'create' && isVoidOnly(formik.values.job_type) && (
							<div className="flex items-center px-3">
								<div className="w-full">
									<Checkbox
										title="Visits"
										id="visit_options"
										values={formik.values.visit_options}
										onChange={formik.setFieldValue}
										options={voidVisitOptions}
									/>
								</div>
							</div>
						)}
						<VariationTaskForm
							formik={formik}
							formType="create"
							clientsData={clientsData}
							contactsData={contactsData}
							client_id={formik.values.client_id}
							hideType={isVoidOrMobileJob(formik.values.job_type)}
						/>
					</div>
				) : null}
				<div className="flex items-center px-3">
					<div className="w-1/2">
						<Dropdown
							label="Job Status"
							id="job_status"
							options={
								formType === 'create'
									? job_status_options
									: job_status_options_Edit
							}
							value={formik.values.job_status}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							disabled={
								formType === 'create' ||
								formik.values.job_status === 'Pending Handover'
								// || isMobileOnly(formik.values.job_type) === true
							}
						/>
					</div>
					<div className="w-1/2">
						<Dropdown
							label="Invoice Paid?"
							id="invoice_paid"
							options={[
								{ label: 'Yes', value: 'Y' },
								{ label: 'No', value: 'N' },
								{ label: 'Partially', value: 'P' },
							]}
							value={formik.values.invoice_paid}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
						/>
					</div>
				</div>
				{formType == 'update' && (
					<div className="flex items-center px-3">
						<div className="w-1/2">
							<Dropdown
								label="Status"
								id="status"
								options={active_inactive_options}
								value={formik.values.status}
								onChange={formik.setFieldValue}
								onBlur={formik.setFieldTouched}
							/>
						</div>
					</div>
				)}
				<div className="flex items-center px-3">
					<div className="w-1/2">
						<TextArea
							title="Notes"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Enter any relevant notes..."
							id="notes"
							value={formik.values.notes}
							error={formik.errors.notes}
							rows={5}
						/>
					</div>
				</div>
				<CreateClientsForm
					open={clientForm}
					setOpen={setClientForm}
					heading="Create New Client"
					formType="create"
				/>
			</SideModal>
		</>
	)
}
