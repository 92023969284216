import { AppRoutes } from 'config'
import { Container, TaskContainer } from './styledComponents'
import { Link, Location, useNavigate } from 'react-router-dom'
import { VehicleServices } from 'services'

interface EventProps {
	timeOn: string
	type: string
	job_id: number
	teamLeader: string
	site: string
	vehicles: string[]
	jobData: any
	clientData: any
	internal_notes: string
	staff_names: string[]
}

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	event: any
	location: Location
	staff?: boolean
}

/* Possible options are:
Install - #A50657
Raise - #E74A20
Lower - #E4C446
Dismantle - #2E8140
Modify - #4055B4
Strip/Move - #872AA7
Travel - #518BF5
*/

const EventView = ({ event, location, staff }: IProps) => {
	const navigate = useNavigate()
	const color = {
		Install: '#C2E4A9',
		Dismantle: '#E0AC89',
		Adjustment: '#EF4444',
		Remedial: '#fbb724',
		Reinstall: '#D6A2D9',
		Removal: '#E0AC89',
		Travel: '#E5B066',
		'Strip/Move': '#FDFEB5',
		Raise: '#D6A2D9',
		Lower: '#ACDAF5',
		Modify: '#A99EF3',
		Drop: '#ACDAF5',
	}

	const eventProp: EventProps = event.extendedProps

	const jobType = eventProp.jobData.job_type

	const { data: vehicleData, isLoading: vehicleLoading } =
		VehicleServices.useVehicleById(eventProp.vehicles[0])
	const { publicId: visitId } = event._def
	const styles = () => ({
		color: 'black',
		backgroundColor: color[eventProp.type as keyof typeof color] || '#10B981',
	})

	// console.log(eventProp.jobData)

	const handleClick = () => {
		navigate(
			AppRoutes.privateRoutes.visitsEdit.replace(':id', visitId.toString()),
			{
				state: { background: location, name: 'editVisit' },
			}
		)
	}

	return (
		<Container
			onClick={handleClick}
			padding="0.5px"
			fontSize="0.8rem"
			color={styles().color}
			backgroundColor={styles().backgroundColor}>
			{/* <TaskContainer>
					<b>Visit# </b> {String(event.id)} <b> Job #:</b> {eventProp.job_id}
				</TaskContainer>
				<TaskContainer>
					<b>Team Leader:</b> {eventProp.teamLeader} <b>Time On: </b>{' '}
					{eventProp.timeOn}
				</TaskContainer> */}
			<TaskContainer>
				<b>Client:</b> {eventProp?.clientData?.client_name}
			</TaskContainer>
			<TaskContainer>
				<b>Address:</b> {eventProp?.site}
			</TaskContainer>
			<TaskContainer>
				<b>Visit Type:</b> {eventProp?.type}
			</TaskContainer>
			<TaskContainer>
				<b>Vehicle:</b>{' '}
				{vehicleData
					? `${vehicleData?.CodeName} - ${vehicleData?.Rego}`
					: 'No Vehicle'}
			</TaskContainer>
			<TaskContainer>
				<b>Internal Notes: </b> {eventProp?.internal_notes}
			</TaskContainer>
			{staff && (
				<TaskContainer>
					<b>Staff: </b>
					{staff &&
						eventProp?.staff_names.map((name, index) => (
							<span key={index}>
								{name}
								{index < eventProp.staff_names.length - 1 ? ', ' : ''}
							</span>
						))}
				</TaskContainer>
			)}
		</Container>
	)
}

export default EventView
