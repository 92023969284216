import { ClientServices, StaffServices, VisitServices } from 'services'
import EventView from './EventContent'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { isVoidOnly } from 'utilities'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'

export function JobSchedulerUtils() {
	const location = useLocation()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventContent = (view: any) => (
		<EventView event={view.event} location={location} />
	)
	const eventContentStaff = (view: any) => (
		<EventView staff event={view.event} location={location} />
	)
	const { data: clientData, isLoading: clientsLoading } =
		ClientServices.useClients()
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()
	const { data: visitsData, isLoading: visitsLoading } =
		VisitServices.useVisits()
	const userState = useSelector((store: AppStore) => store.user)

	const { updateVisit } = VisitServices.useUpdateVisit()
	const headerToolbar = {
		left: 'title',
		right: 'prev,today,next',
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventDrop = async ({ event }: any) => {
		const visitId = Number(event?.id)
		const newDate = moment(event?.start).format('DD/MM/YYYY')
		if (visitId && newDate) {
			const payload = {
				date: newDate,
			}
			await updateVisit(visitId, payload)
		}
	}

	const formatEvents = () => {
		if (!visitsLoading && visitsData && staffData) {
			const nonVoidVisits = visitsData?.filter(
				(visit: any) =>
					!isVoidOnly(visit?.jobData?.job_type) &&
					visit.status === 'Active' &&
					visit?.jobData?.status === 'Active' &&
					(userState.branch === 'Head Office' ||
						visit?.jobData?.branch === userState.branch)
			)
			return nonVoidVisits.map(
				(visit: {
					time_on: string
					id: any
					staff_ids: any[]
					jobData: { job_num: any; site: any; client_id: any }
					visit_status: any
					teamLeaderData: { staff_name: any; id: any }
					vehicle_ids: any
					type: any
					internal_notes: string
					date: moment.MomentInput
				}) => {
					const startDate = moment(visit?.date, 'DD/MM/YYYY')
						const time_on =
							(visit.time_on !== null &&
							visit.time_on !== undefined &&
							visit.time_on !== '')
								? moment(visit?.time_on, 'HH:mm')
								: null
						if (time_on) {
							startDate.set({
								hour: time_on.get('hour'),
								minute: time_on.get('minute'),
							})
						}
					return {
						id: visit.id,
						resourceIds: [visit?.teamLeaderData?.id],
						job_id: visit?.jobData?.job_num,
						title: visit.visit_status,
						teamLeader: visit.teamLeaderData?.staff_name || '',
						vehicles: visit?.vehicle_ids || [],
						type: visit?.type,
						internal_notes: visit?.internal_notes,
						jobData: visit.jobData,
						clientData: clientData?.find(
							(client: { id: any }) =>
								String(client.id) === String(visit.jobData?.client_id)
						),
						site: visit.jobData?.site,
						timeOn: visit?.time_on,
						start: startDate.format('YYYY-MM-DDTHH:mm:ss'),
						staff_names: staffData
							.filter((staff) => visit?.staff_ids.includes(String(staff.id)))
							.map((staff) => staff.staff_name),
					}
				}
			)
		}
		return []
	}

	return {
		formatEvents,
		visitsData,
		visitsLoading: visitsLoading || clientsLoading || staffLoading,
		headerToolbar,
		eventDrop,
		eventContent,
		eventContentStaff,
	}
}
