import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { StyledWrapper } from '../../common/Scheduler/styledComponents'
import { JobSchedulerUtils } from 'common/Scheduler'
import { PageHeading, Spinner } from 'common'
import { useEffect, useState } from 'react'
import { VisitForm } from 'components/Visits'
import { PlusIcon } from '@heroicons/react/24/solid'
import { DateSelectArg } from '@fullcalendar/core'
import { CrewsServices, StaffServices } from 'services'
import { CreateCrewsForm } from 'components/Crews'
import React from 'react'
import { StaffType } from 'models'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
interface IFormatResource {
	id: string
	title: string
}

export function StaffScheduler() {
	const [openVisitForm, setOpenVisitForm] = useState(false)
	const [openCrewForm, setOpenCrewForm] = useState(false)
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()
	const userState = useSelector((store: AppStore) => store.user)
	const calendarRef = React.createRef<FullCalendar>()
	const [selectedDate, setSelectedDate] = useState({
		start: '',
		team_leader: '',
	})
	const {
		visitsData,
		visitsLoading,
		headerToolbar,
		eventContentStaff: eventContent,
		eventDrop,
		formatEvents,
	} = JobSchedulerUtils()
	const formatResources = () => {
		if (staffData && visitsData) {
			const staffMembers: IFormatResource[] = []

			// console.log('uniqueTeamLeaders', uniqueTeamLeaders)
			staffData
				.filter(
					(staff) =>
						staff.status === 'Active' &&
						staff.type !== 'Truck Driver' &&
						(userState.branch === 'Head Office' ||
							staff.branch === userState.branch)
				)
				.map((staff: any) => {
					staffMembers.push({
						id: staff?.id,
						title: `${staff.staff_name}`,
					})
				})
			return staffMembers
		}
		return []
	}

	useEffect(() => {
		if (!openVisitForm) {
			setSelectedDate({
				start: '',
				team_leader: '',
			})
		}
	}, [openVisitForm])

	const handleDateSelect = (selectInfo: DateSelectArg) => {
		setSelectedDate({
			start: selectInfo.startStr,
			team_leader: selectInfo.resource ? selectInfo.resource.id : '',
		})
		setOpenVisitForm(true)
	}

	if (visitsLoading || staffLoading) {
		return <Spinner />
	}

	// console.log(staffWithCrew(), 'staffWithCrew')
	return (
		<StyledWrapper>
			<div className="flex justify-end mb-6">
				<button
					type="button"
					className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
					onClick={() => setOpenCrewForm(true)}>
					<PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
					Create Crew
				</button>
				<button
					type="button"
					className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
					onClick={() => setOpenVisitForm(true)}>
					<PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
					Create Visit
				</button>
			</div>
			<FullCalendar
				ref={calendarRef}
				resourceAreaHeaderContent="Team Leader"
				resourceLabelContent={(arg) => <div>{`${arg.resource.title}`}</div>}
				resourceAreaWidth="10%"
				headerToolbar={{
					...headerToolbar,
					center: 'Day,Week,Month',
				}}
				slotMinWidth={100}
				views={{
					Day: {
						type: 'resourceTimelineDay',
						slotDuration: { days: 1 },
						buttonText: 'Day',
						slotLabelFormat: [
							{ weekday: 'long' }, // lower level of text
						],
					},
					Week: {
						type: 'resourceTimelineWeek',
						slotLabelInterval: { days: 1 },
						weekends: true,
						buttonText: 'Week',
						slotLabelFormat: [
							{ weekday: 'short' }, // lower level of text
						],
					},
					Month: {
						type: 'resourceTimelineMonth',
						buttonText: 'Month',
						slotLabelInterval: { days: 1 },
						weekends: true,
					},
				}}
				eventContent={eventContent}
				plugins={[
					dayGridPlugin,
					timeGridPlugin,
					interactionPlugin,
					resourceTimelinePlugin,
				]}
				initialView="Day"
				height="auto"
				// selectable
				// select={handleDateSelect}
				firstDay={1}
				resourceOrder={'title'}
				resources={formatResources()}
				filterResourcesWithEvents
				events={formatEvents()}
				// eventDrop={eventDrop}
				schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
			/>
			<VisitForm
				heading="Create Visit"
				setOpen={setOpenVisitForm}
				formType="create"
				open={openVisitForm}
				startDate={selectedDate.start}
				teamLeader={selectedDate.team_leader}
			/>
			<CreateCrewsForm
				heading="Create Crew"
				setOpen={setOpenCrewForm}
				formType="create"
				open={openCrewForm}
			/>
		</StyledWrapper>
	)
}
