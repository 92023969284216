import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { Spinner } from 'common'
import { StyledWrapper } from '../../common/Scheduler/styledComponents'
import { useLocation } from 'react-router-dom'
import EventView from 'common/Scheduler/EventContent'
import { ClientServices, VisitServices } from 'services'
import moment from 'moment'
import { isVoidOnly, isVoidOrMobileJob } from 'utilities'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { format } from 'date-fns'

export function VoidScheduler() {
	const location = useLocation()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventContent = (view: any) => (
		<EventView event={view.event} location={location} />
	)
	const { data: visitsData, isLoading: visitsLoading } =
		VisitServices.useVisits()
	const userState = useSelector((store: AppStore) => store.user)
	const { updateVisit } = VisitServices.useUpdateVisit()
	const { data: clientData, isLoading: clientsLoading } =
		ClientServices.useClients()
	const headerToolbar = {
		left: 'title',
		right: 'prev,today,next',
	}

	const voidJobVisits = visitsData?.filter(
		(visit: any) =>
			isVoidOnly(visit?.jobData?.job_type) &&
			visit.status === 'Active' &&
			(visit.jobData.branch === userState.branch ||
				userState.branch === 'Head Office')
	)

	// console.log(visitsData)
	// console.log(voidJobVisits)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventDrop = async ({ event }: any) => {
		const visitId = Number(event?.id)
		const newDate = moment(event?.start).format('DD/MM/YYYY')
		if (visitId && newDate) {
			const payload = {
				date: newDate,
			}
			await updateVisit(visitId, payload)
		}
	}

	const formatEvents = () => {
		if (!visitsLoading && visitsData && voidJobVisits) {
			return voidJobVisits
				?.filter(
					(visit: { status: string; jobData: any }) =>
						visit.status === 'Active' && visit?.jobData?.status === 'Active'
				)
				?.map(
					(visit: {
						time_on: string
						id: any
						staff_ids: any[]
						jobData: { job_num: any; site: any; client_id: any }
						visit_status: any
						teamLeaderData: { staff_name: any; id: any }
						vehicle_ids: any
						type: any
						internal_notes: string
						date: moment.MomentInput
					}) => {
						const startDate = moment(visit?.date, 'DD/MM/YYYY')
						const time_on =
							visit.time_on !== null &&
							visit.time_on !== undefined &&
							visit.time_on !== ''
								? moment(visit?.time_on, 'HH:mm')
								: null
						if (time_on) {
							startDate.set({
								hour: time_on.get('hour'),
								minute: time_on.get('minute'),
							})
						}
						return {
							id: visit.id,
							resourceIds: [
								...visit?.staff_ids.map((staff: any) => staff),
								visit?.teamLeaderData?.id,
							],
							job_id: visit?.jobData?.job_num,
							jobData: visit.jobData,
							clientData: clientData?.find(
								(client: { id: any }) =>
									String(client.id) === String(visit.jobData?.client_id)
							),
							title: visit.visit_status,
							teamLeader: visit.teamLeaderData?.staff_name || '',
							vehicles: visit?.vehicle_ids || [],
							type: visit?.type,
							internal_notes: visit?.internal_notes,
							site: visit.jobData?.site,
							timeOn: visit?.time_on,
							start: startDate.format('YYYY-MM-DDTHH:mm:ss'),
						}
					}
				)
		}
		return []
	}

	if (visitsLoading || clientsLoading) {
		return <Spinner />
	}

	return (
		<StyledWrapper>
			<FullCalendar
				headerToolbar={{
					...headerToolbar,
					center: 'Day,Week,Month',
				}}
				views={{
					Month: {
						type: 'dayGridMonth',
						slotLabelInterval: { days: 1 },
						buttonText: 'Month',
						weekends: true,
						eventContent,
					},
					Week: {
						type: 'dayGridWeek',
						slotLabelInterval: { days: 1 },
						buttonText: 'Week',
						weekends: true,
						eventContent,
						dayHeaderContent: (args) => {
							const formattedDate = format(args.date, 'dd/MM')
							const dayOfWeek = format(args.date, 'EEE')
							return (
								<span>
									{dayOfWeek} - {formattedDate}
								</span>
							)
						},
					},
					Day: {
						type: 'dayGridDay',
						slotLabelInterval: { days: 1 },
						buttonText: 'Day',
						eventOrderStrict: true,
						eventOrder: 'start',
						weekends: true,
						eventContent,
					},
				}}
				eventContent={eventContent}
				plugins={[
					dayGridPlugin,
					timeGridPlugin,
					interactionPlugin,
					resourceTimelinePlugin,
				]}
				initialView="Month"
				height="auto"
				editable
				selectable
				eventOrder={'start'}
				dayMaxEvents
				firstDay={1}
				events={formatEvents()}
				eventDrop={eventDrop}
				schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
			/>
		</StyledWrapper>
	)
}
